import { Controller } from '@hotwired/stimulus'
import confetti from 'https://esm.run/canvas-confetti@1'

export default class extends Controller {
  burst () {
    confetti({
      angle: this.randomInRange(55, 125),
      spread: this.randomInRange(50, 70),
      particleCount: this.randomInRange(50, 100),
      origin: { y: 0.6 },
      colors: ['#CE4A49']
    })
  }

  randomInRange (min, max) {
    return Math.random() * (max - min) + min
  }
}
