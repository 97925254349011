import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['glow', 'result', 'slider']
  static values = {
    base: {
      type: Number
    },
    default: Number,
    type: String
  }

  connect () {
    this.setInitialValues()
    this.updateResult()
    this.updateBackground()
  }

  update () {
    this.updateResult()
    this.updateBaseValue()
    this.updateBackground()
    this.dispatchSliderEvent()
  }

  setInitialValues () {
    this.sliderTarget.value = this.defaultValue || this.sliderTarget.value
    this.updateBaseValue()
  }

  updateResult () {
    this.resultTarget.textContent = this.typeValue === 'income' ? this.formatter(this.sliderTarget.value) : this.sliderTarget.value
  }

  updateBaseValue () {
    this.baseValue = (this.sliderTarget.value - this.sliderTarget.min) / (this.sliderTarget.max - this.sliderTarget.min) * 100
  }

  updateBackground () {
    this.sliderTarget.style.background = `linear-gradient(to right, #CE4A49 0%, #CE4A49 ${this.baseValue}%, #F2F4F5 ${this.baseValue}%, #F2F4F5 100%)`
    this.glowTarget.style.width = this.baseValue > 50 ? `calc(${this.baseValue}% - 1%)` : `${this.baseValue}%`
  }

  dispatchSliderEvent () {
    const event = new window.CustomEvent('slider', {
      detail: {
        type: this.typeValue,
        value: this.sliderTarget.value
      }
    })
    window.dispatchEvent(event)
  }

  formatter (value) {
    return new Intl.NumberFormat('fr-FR',
      {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
      }).format(
      value
    )
  }
}
