import { Controller } from '@hotwired/stimulus'
import algoliasearch from 'algoliasearch/lite'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'

const searchClient = algoliasearch(
  process.env.ALGOLIA_APPLICATION_ID,
  process.env.ALGOLIA_SEARCH_API_KEY
)

function debouncePromise (fn, time) {
  let timerId

  return function debounced (...args) {
    if (timerId) {
      clearTimeout(timerId)
    }

    return new Promise((resolve) => {
      timerId = setTimeout(() => resolve(fn(...args)), time)
    })
  }
}

const debounced = debouncePromise((sources) => sources, 500)

export default class extends Controller {
  static values = { defaultLogoPath: String, placeholder: String }

  connect () {
    const defaultLogoPath = this.defaultLogoPathValue

    this.autocompleteSearch = autocomplete({
      container: this.element,
      detachedMediaQuery: '(max-width: 680px)',
      placeholder: this.placeholderValue,
      autoFocus: true,
      translations: {
        clearButtonTitle: 'Effacer',
        detachedCancelButtonText: 'Effacer'
      },
      getSources ({ query }) {
        return debounced([
          {
            sourceId: 'querySuggestions',
            getItems ({ query }) {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: process.env.ALGOLIA_SHOPS_INDEX_NAME,
                    query
                  }
                ]
              })
            },
            templates: {
              item ({ item, components, html }) {
                return html`
                  <a href="${item.storefront_url}" target="_blank">
                    <div>
                      <img
                        src="${item.vectorized_logo_url || defaultLogoPath}"
                        alt="${item.slug}"
                      />
                      <span class="ml-3 club-name">
                        ${components.Highlight({
                          hit: item,
                          attribute: 'club_name'
                        })}
                      </span>
                      <span class="hidden md:inline sport">${item.sport}</span>
                    </div>
                    <span class="g-atoms--link_cell-link-cell g-atoms--link_cell-external">
                      <span class="hidden md:inline">Voir la boutique</span>
                    </span>
                  </a>
                `
              },
              noResults () {
                return 'Aucun résultat.'
              }
            }
          }
        ])
      }
    })
  }

  disconnect () {
    this.autocompleteSearch.destroy()
  }
}
