import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'

const app = Application.start()

const controllers = import.meta.glob([
  './**/*_controller.js',
  '../../common/controllers/**/*_controller.js'
], { eager: true })

registerControllers(app, controllers)
