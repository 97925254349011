import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['clubs', 'income', 'result']
  static values = {
    club: {
      type: Number,
      default: 50
    },
    income: {
      type: Number,
      default: 2500
    },
    operatingMargin: {
      type: Number,
      default: 15
    },
    total: {
      Number,
      default: 18750
    }
  }

  connect () {
    this.setInitialValues()
  }

  setInitialValues () {
    this.resultTarget.textContent = this.formatter(this.totalValue)
  }

  calculate (event) {
    const { type, value } = event.detail

    if (type === 'club') {
      this.clubValue = value
    } else if (type === 'income') {
      this.incomeValue = value
    }

    this.totalValue = this.clubValue * this.incomeValue * this.operatingMarginValue / 100
    this.resultTarget.textContent = this.formatter(this.totalValue)

    if (this.totalValue >= (this.clubsTarget.max * this.incomeTarget.max * this.operatingMarginValue / 100)) {
      this.dispatchEasterEvent()
    }
  }

  formatter (value) {
    return new Intl.NumberFormat('fr-FR',
      {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0
      }).format(
      value
    )
  }

  dispatchEasterEvent () {
    const event = new window.CustomEvent('easter')
    window.dispatchEvent(event)
  }
}
